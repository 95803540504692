export default {
	startsWith: '开始于',
	contains: '含有',
	notContains: '不包含',
	endsWith: '结束于',
	equals: '等于',
	notEquals: '不等于',
	noFilter: '没有过滤器',
	lt: '少于',
	lte: '小于或等于',
	gt: '大于',
	gte: '大于或等于',
	dateIs: '日期是',
	dateIsNot: '日期不是',
	dateBefore: '日期早于',
	dateAfter: '日期在之后',
	clear: '清除',
	apply: '申请',
	matchAll: '匹配所有',
	matchAny: '任意匹配',
	addRule: '添加规则',
	removeRule: '除去规则',
	accept: '是的',
	reject: '没有',
	choose: '选择',
	upload: '上传',
	cancel: '取消',
	dayNames: ['星期日','星期一','星期二','星期三','星期四','星期五','星期六'],
	dayNamesShort: ['日','一','二','三','四','五','六'],
	dayNamesMin: ['日','一','二','三','四','五','六'],
	monthNames: ['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月'],
	monthNamesShort: ['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月'],
	today: '今天',
	weekHeader: '周',
	firstDayOfWeek: 1,
	dateFormat: 'yy/mm/dd',
	weak: '虚弱的',
	medium: '媒介',
	strong: '强的',
	passwordPrompt: '输入密码',
	emptyFilterMessage: '未找到结果',
	emptyMessage: '没有可用的选项',
	uploadedFiles: 'Uploaded files',
	uploadFailed: 'Upload Failed',
	maxFileLimit: 'Max file limit of ${limit} execeeded!',
	aria: {
		trueLabel: "True",
		falseLabel: "False",
		nullLabel: "Not Selected",
		pageLabel: "Page",
		firstPageLabel: "First Page",
		lastPageLabel: "Last Page",
		nextPageLabel: "Next Page",
		previousPageLabel: "Previous Page",
		selectLabel: "Select",
		unselectLabel: "Unselect",
		expandLabel: "Expand",
		collapseLabel: "Collapse"
	},
	validations: {
		required: "值是必需的。",
		undefined: "值是必需的。",
		email: "值不是有效的电子邮件地址",
		minLength: "该字段的长度至少为 ${min} 个字符",
		maxLength: "允许的最大长度为 ${max}",
		minValue: "允许的最小值是 ${min}",
		maxValue: "允许的最大值是 ${max}",
		numeric: "值必须是数字",
		ipAddress: "该值不是有效的 IP 地址",
		sameAs: "该值必须等于 ${otherName} 值",
	}
}