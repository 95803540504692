export default {
	startsWith: 'इसके साथ आरंभ होता है',
	contains: 'शामिल है',
	notContains: 'इसमें शामिल नहीं है',
	endsWith: 'इसी के साथ समाप्त होता है',
	equals: 'बराबर',
	notEquals: 'बराबर नहीं',
	noFilter: 'कोई फिल्टर नहीं',
	lt: 'इससे कम',
	lte: 'से कम या बराबर',
	gt: 'से बड़ा',
	gte: 'इससे बड़ा या इसके बराबर',
	dateIs: 'तिथि है',
	dateIsNot: 'तारीख नहीं है',
	dateBefore: 'तारीख पहले है',
	dateAfter: 'तारीख बाद की है',
	clear: 'स्पष्ट',
	apply: 'आवेदन करना',
	matchAll: 'सभी का मिलान करें',
	matchAny: 'कोई भी मिलान करें',
	addRule: 'नियम जोड़ें',
	removeRule: 'नियम हटाएं',
	accept: 'हां',
	reject: 'नहीं',
	choose: 'चयन करें',
	upload: 'अपलोड',
	cancel: 'रद्द करना',
	dayNames: ['रविवार', 'सोमवार', 'मंगलवार', 'बुधवार', 'गुरुवार', 'शुक्रवार', 'शनिवार'],
	dayNamesShort: ['रवि', 'सोम', 'मंगल', 'बुध', 'गुरु', 'शुक्र', 'शनि'],
	dayNamesMin: ['रवि', 'सोम', 'मंगल', 'बुध', 'गुरु', 'शुक्र', 'शनि'],
	monthNames: ['जनवरी ','फरवरी','मार्च','अप्रेल','मै','जून','जूलाई','अगस्त ','सितम्बर','आक्टोबर','नवम्बर','दिसम्बर'],
	monthNamesShort: ['जन', 'फर', 'मार्च', 'अप्रेल', 'मै', 'जून','जूलाई', 'अग', 'सित', 'आक्ट', 'नव', 'िद'],
	today: 'आज',
	weekHeader: 'हफ्ता',
	firstDayOfWeek: 1,
	dateFormat: 'mm/dd/yy',
	weak: 'कमज़ोर',
	medium: 'मध्यम',
	strong: 'मज़बूत',
	passwordPrompt: 'एक पासवर्ड दर्ज करें',
	emptyFilterMessage: 'कोई परिणाम नहीं मिला',
	emptyMessage: 'कोई उपलब्ध विकल्प नहीं',
	uploadedFiles: 'Uploaded files',
	uploadFailed: 'Upload Failed',
	maxFileLimit: 'Max file limit of ${limit} execeeded!',
	aria: {
		trueLabel: "True",
		falseLabel: "False",
		nullLabel: "Not Selected",
		pageLabel: "Page",
		firstPageLabel: "First Page",
		lastPageLabel: "Last Page",
		nextPageLabel: "Next Page",
		previousPageLabel: "Previous Page",
		selectLabel: "Select",
		unselectLabel: "Unselect",
		expandLabel: "Expand",
		collapseLabel: "Collapse"
	},
	validations: {
		required: "मान आवश्यक है।",
		undefined: "मान आवश्यक है।",
		email: "मान एक मान्य ईमेल पता नहीं है",
		minLength: "यह फ़ील्ड कम से कम ${min} वर्ण लंबा होना चाहिए",
		maxLength: "अधिकतम लंबाई की अनुमति ${max} है",
		minValue: "न्यूनतम मान की अनुमति ${min} है",
		maxValue: "अधिकतम अनुमत मूल्य ${max} है",
		numeric: "मान संख्यात्मक होना चाहिए",
		ipAddress: "मान एक मान्य IP पता नहीं है",
		sameAs: "मान ${otherName} मान के बराबर होना चाहिए",
	}
}