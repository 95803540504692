export default {
	startsWith: 'Inicia com',
	contains: 'Contém',
	notContains: 'Não contém',
	endsWith: 'Termina com',
	equals: 'É igual a',
	notEquals: 'Não é igual',
	noFilter: 'Sem filtro',
	lt: 'Menor que',
	lte: 'Menos que ou igual a',
	gt: 'Maior que',
	gte: 'Maior que ou igual a',
	dateIs: 'Data é',
	dateIsNot: 'Data não é',
	dateBefore: 'A data é antes',
	dateAfter: 'A data é depois',
	clear: 'claro',
	apply: 'Aplique',
	matchAll: 'Corresponder a todos',
	matchAny: 'Corresponder a Qualquer',
	addRule: 'Adicionar regra',
	removeRule: 'Remover Regra',
	accept: 'Sim',
	reject: 'Não',
	choose: 'Escolher',
	upload: 'Fazer upload',
	cancel: 'Cancelar',
	dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
	dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
	monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
	today: 'Hoje',
	weekHeader: 'Semana',
	firstDayOfWeek: 0,
	dateFormat: 'dd/mm/yy',
	weak: 'Fraco',
	medium: 'Médio',
	strong: 'Forte',
	passwordPrompt: 'Digite uma senha',
	emptyFilterMessage: 'Nenhum resultado encontrado',
	emptyMessage: 'Nenhuma opção disponível',
	uploadedFiles: 'Uploaded files',
	uploadFailed: 'Upload Failed',
	maxFileLimit: 'Max file limit of ${limit} execeeded!',
	aria: {
		trueLabel: "True",
		falseLabel: "False",
		nullLabel: "Não selecionado",
		pageLabel: "Página",
		firstPageLabel: "Primeira Página",
		lastPageLabel: "Última Página",
		nextPageLabel: "Próxima",
		previousPageLabel: "Anterior"
	},
	validations: {
		required: "O valor é obrigatório.",
		undefined: "O valor é obrigatório.",
		email: "O valor não é um endereço de e-mail válido",
		minLength: "Este campo deve ter pelo menos ${min} caracteres",
		maxLength: "O comprimento máximo permitido é ${max}",
		minValue: "O valor mínimo permitido é ${min}",
		maxValue: "O valor máximo permitido é ${max}",
		numeric: "O valor deve ser numérico",
		ipAddress: "O valor não é um endereço IP válido",
		sameAs: "O valor deve ser igual ao valor ${otherName}",
	}
}